import React from 'react';
import dayjs from 'dayjs';

import { FaFacebookF, FaTwitter, FaYoutube, FaRss, FaReddit, FaPinterest, FaInstagram } from 'react-icons/fa';
import { LinkButtonAria, TextButton, cn } from '@riac/design-system';
import { Icon } from 'src/components/Footer/Icon/Icon';
import RIACLogo from 'images/riac-logo.png';

const SocialSection = (props): JSX.Element => {
  return (
    <div
      className = {cn('flex flex-wrap gap-1 justify-center w-full lg:m-3 lg:gap-3 text-4xl', props.className)}
    >
      <Icon
        href='https://www.facebook.com/RockIslandAuctionCompany/'
        title='Facebook'
        icon={<FaFacebookF />}
      />
      <Icon
        href='https://twitter.com/RIAuction'
        title='Twitter'
        icon={<FaTwitter />}
      />
      <Icon
        href='https://www.youtube.com/user/RockIslandAuction'
        title='YouTube'
        icon={<FaYoutube />}
      />
      <Icon
        href='/riac-blog'
        title='Blog'
        icon={<FaRss />}
      />
      <Icon
        href='https://www.reddit.com/r/RockIslandAuction/'
        title='Reddit'
        icon={<FaReddit />}
      />
      <Icon
        href='https://www.pinterest.com/riauction/_created/'
        title='Pinterest'
        icon={<FaPinterest />}
      />
      <Icon
        href='https://www.instagram.com/rockislandauction/'
        title='Instagram'
        icon={<FaInstagram />}
      />
    </div>
  );
};


const FooterButton = (props: React.PropsWithChildren<{className?: string, to: string}>): JSX.Element => (
  <TextButton
    {...props}
    className = {cn('leading-12 justify-center items-center text-sc-gold-50 hover:text-sc-gold-20', props.className)}
  >
  </TextButton>
);

const FooterStrong = (props: React.PropsWithChildren<{className?: string}>): JSX.Element => (
  <strong
    {...props}
    className = {cn('mb-1 text-[3rem] text-white-217 font-amiri small-caps', props.className)}
  />
);

export const Footer = ():JSX.Element => (
  <footer className='print:hidden pb-16 md:py-8 bg-neutral-900'>
    <h1 className={'text-center mb-5 font-bold'}>History Lives Here</h1>
    <div
      className="flex flex-wrap gap-2.5 justify-center justify-items-center px-2 pt-3 \
                   lg:px-5 lg:grid lg:grid-cols-footer"
    >
      <div className="flex flex-col items-center flex-1">
        <div>
          <LinkButtonAria to="/">
            <img src={RIACLogo} alt="Rock Island Auction Company"/>
          </LinkButtonAria>
        </div>
        <FooterButton to="tel:13097971500">1-309-797-1500</FooterButton>
        <FooterButton to="tel:18002388022">1-800-238-8022</FooterButton>
        <FooterButton to="mailto:info@rockislandauction.com">info@rockislandauction.com</FooterButton>
        <SocialSection className="hidden lg:flex max-w-56"/>
      </div>
      <div className="flex flex-col gap-8 flex-1 min-w-64">
        <div>
          <address className="text-center">
            <FooterStrong>RIAC Rock Island</FooterStrong><br/>
          7819 42nd Street West<br/>
          Rock Island, Illinois 61201, USA<br/>
          </address>
          <div className="text-center text-base">
          8:00am - 5:00pm, Mon - Fri
          </div>
        </div>
        <div>
          <address className="text-center">
            <FooterStrong><FooterButton to="/location/bedford">RIAC Bedford</FooterButton></FooterStrong><br/>
          3600 Harwood Road<br/>
          Bedford, Texas 76021, USA<br/>
          </address>
          <div className="text-center text-base">
          8:00am - 5:00pm, Mon - Fri
          </div>
        </div>
      </div>
      <div className="flex w-full lg:gap-8 text-center">
        <div className="flex-1">
          <FooterStrong>Navigation</FooterStrong>
          <ul className="list-none">
            <li><FooterButton to="/gun-auctions">Auctions</FooterButton></li>
            <li><FooterButton to="/consignment">Consignment</FooterButton></li>
            <li><FooterButton to="/riac-blog">Blog</FooterButton></li>
            <li><FooterButton to="/faq">FAQ</FooterButton></li>
            <li><FooterButton to="/news">News</FooterButton></li>
            <li><FooterButton to="/about-us">About&nbsp;Us</FooterButton></li>
          </ul>
        </div>
        <div className="flex-1">
          <FooterStrong>More Info</FooterStrong>
          <ul className="list-none">
            <li><FooterButton to="/auction-policy">Auction Policy</FooterButton></li>
            <li><FooterButton to="/terms-and-conditions">Terms&nbsp;&&nbsp;Conditions</FooterButton></li>
            <li><FooterButton to="/privacy-policy">Privacy Policy</FooterButton></li>
            <li><FooterButton to="/careers">Job&nbsp;Postings&nbsp;&&nbsp;Careers</FooterButton></li>
            <li><FooterButton to="/contact">Contact</FooterButton></li>
            <li><FooterButton to="/order-catalog">Order&nbsp;a&nbsp;Catalog</FooterButton></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="w-full bg-grey-25 lg:pt-8">
      <SocialSection className='lg:hidden text-3xl px-12 py-4'/>
      <div className='max-w-3xl lg:max-w-4xl m-auto text-md px-16 lg:px-8'>
        &copy; {dayjs().format('YYYY')} Rock Island Auction Company. RIAC believes that this website is
        accessible to the widest possible audience pursuant to the guidelines of the Americans with Disability Act.
        {' '} <LinkButtonAria to='/accessibility-statement/'>Click here for more information.</LinkButtonAria>
      </div>
      <div className='max-w-3xl lg:max-w-4xl m-auto text-md px-16 lg:px-8 py-4'>
        {' '} <LinkButtonAria to='https://transparency-in-coverage.uhc.com/'>Healthcare Transparency in Coverage.</LinkButtonAria>
      </div>
    </div>
  </footer>
);
